<template>
  <div class="not-found">
    <img src="../../assets/not-found/group-avator.svg" alt="Group Avatar">
    <div class="not-found__title">{{ title }}</div>
    <div class="not-found__content">{{ content }}</div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  data: () => ({
    title: '404 Error',
    content: '集團代碼輸入錯誤，請聯繫客服專員。',
  }),
}
</script>

<style lang="scss" scoped>
  .not-found {
    @apply w-full h-full flex flex-col justify-center items-center;

    &__title {
      @apply mt-[32px] text-[60px] leading-[70px] font-bold;
    }

    &__content {
      @apply mt-[16px] text-[#767676] text-[20px];
    }
  }
</style>
